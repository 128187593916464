.c-icon--tricolor {
    height: 4px;
    background-image: linear-gradient(to right,#128ddd 0%,#128ddd 33.3333%,#2ebf62 33.3333%,#2ebf62 66.6666%,#007749 66.6666%);
}

.c-header__logo--medium_un {
    Width: 202px;
    Height: 24px;
    margin-left: 12%;
    margin-top: 36px;
}

.rectangle_un {   
    width: 67%;    
    margin-left: 16.5%;
}

.logo {
    height: 92px;
    background-color: #FFFFFF;
}

@media (min-width:400px) and (max-width:768px) {
    .c-icon--tricolor {
        width: 90%;
    }
    .rectangle_un {
        margin-left: 11.5%;
    }
}
@media (max-width:400px) {
    .c-icon--tricolor {
        width: 80%;
    }
    .c-header__logo--medium_un {
        Width: 180px;
        Height: 24px;
        margin-left: 7%;
        margin-top: 36px;
    }
    .rectangle_un {
        margin-left: 11.5%;
    }
}

@media (max-width:600px) {
    .cms-container-gutter {
        margin-left: 12px;
        margin-right: 12px;
    }
}

@media (max-width:750px) {
    .paddingTop {
        padding-top: 15px;
    }
}

@media (min-width:600px) {
    .cms-container-gutter {
        margin-left: 40px;
        margin-right: 40px;
    }
}