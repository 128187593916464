.c-breadcrumb {
    list-style: none;
    padding-inline-start: 0;
    padding-left: 0;
    margin-top: 32px;
    font-family: "Open Sans","Segoe UI",Tahoma,sans-serif;
}

.c-breadcrumb-item {
    display: inline-block;
    font-weight: bold;
}

.c-breadcrumb-item--active {
    font-weight: normal;
}

.c-breadcrumb-item + .c-breadcrumb-item:before {
    font-weight: normal;
    padding: 0 5px;
    content: "/\00a0";
    color: #445968;
}

.c-link {
    font-weight: 600;
    color: #0e75c4;
    letter-spacing: normal;
    text-decoration: none;
}

.c-breadcrumb-item:last-child .c-link {
    color: #445968;
    cursor: auto;
    font-weight: 400;
    text-decoration: none;
}

.c-breadcrumb-item:last-child .c-link:hover {
    text-decoration: none;
}

.c-breadcrumb-item + .c-breadcrumb-mobile-item:before {
    font-weight: normal;
    padding: 0 5px;
    content: "/\00a0";
    color: #445968;
}

@media (max-width: 750px) {
    .c-link {
        font-size: 14px;
    }
}
.c-breadcrumb-item:nth-last-child(2) {
    display: inline-block;
}

.paddingTop {
    padding-top: 15px;
}
