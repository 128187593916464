@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.email-subscription {
    font-family: "Open Sans";
}

h2 {
    text-transform: initial !important;
}

.container {
    background-color: white;
    margin-top: 24px;
}

.subtitle {
    color: #213848;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 30px;
    font-family: "Open Sans";
    font-style: normal;
}

.title {
    opacity: 1;
    color: #213848;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 48px;
    font-family: "Open Sans";
    margin-bottom: 16px;
    padding-top: 32px;
    text-transform: capitalize;
}

.cms-container--max-width .title {
    margin-left: 0;
    padding-top: 0;
    margin-top: 10px;
}

.rectangle {
    height: 6px;
    width: 70px;
    background-color: #8bc6c5;
    margin-bottom: 24.5px;
}

.section-header {
    color: #008380;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    margin: 40px 0 8px 0;
    font-family: "Open Sans", sans-serif;
}

section.section {
    border: 2px solid #E7F3F3;
    border-radius: 8px;
    padding: 24px;
}

    .information-container {
        margin-top: 50px;
    }


.list-container {
    position: relative;
}

.unsubscribe-box {
    width: 88%;
    margin: auto;
    background-color: white;
    border-radius: 5px;
    height: 103px;
    border-color: #0083802b;
    border-style: solid;
    border-width: 2px;
    margin-top: 16px;
}

.unsubscribe-box-text {
    margin-top: 15px;
    margin-left: 25px;
    padding-right: 20px;
}

.unsubscribe-box-note {
    margin-top: 15px;
    display: block;
}

.linkable {
    height: 25px;
    width: 552px;
    color: #0E75C4;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    cursor: pointer;
    text-decoration: underline;
    font-family: "Open Sans", sans-serif;
}

.Button-container {
    text-align: center;
    margin: 40px auto 20px auto;
    width: 220px;
}

    .Button-container .c-btn {
        border-radius: 5px;
        background-color: #0E75C4;
    }

    .Button-container .c-btn.c-btn--disabled {
        color: #9dacb6;
        background-color: #dbdee0;
    }

    .hidden {
        display: none;
    }

.captcha-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.toast-notification {
    position: fixed;
    top: 50px;
    right: 50px;
    z-index: 99;
}

    .toast-notification .cds--toast-notification {
        width: auto;
    }

.email-dropdown-container {
    width: 50%;
}

.comboBoxWrapper .simpleIcon .cds--list-box__menu-icon {
    top: 35px;
}

@media screen and (min-width:177px) and (max-width:1056px) {
    .section-header {
        font-size: 1em;
    }

    .linkable {
        font-size: 1em;
    }

    .email-dropdown-container {
        width: 100%;
    }
}

@media (min-width:420px) and (max-width:768px) {
    .container {
        width: 95%;
    }

    .unsubscribe-box-text {
        margin-top: 15px;
        margin-left: 20px;
        font-size: 12px;
        padding-right: 20px;
    }
    .linkable {
        font-size: 12px;
        line-height: 15px;
    }

    .section.information-container p {
        font-size: 12px;
    }
}


@media (min-width:348px) and (max-width:420px) {
    .container {
        width: 93%;
    }

    .title {
        font-size: 30px;
    }

    .section-header {
        font-size: 0.8em;
    }

    .unsubscribe-box {
        height: 125px;
    }

    .unsubscribe-box-text {
        margin-top: 15px;
        margin-left: 20px;
        font-size: 11px;
        padding-right: 10px;
    }

    .linkable {
        font-size: 12px;
        line-height: 15px;
    }

    .section.information-container p {
        font-size: 12px;
    }
}

@media (min-width:288px) and (max-width:350px) {
    .container {
        width: 93%;
    }

    .title {
        font-size: 25px;
    }

    .section-header {
        font-size: 0.7em;
    }

    .unsubscribe-box {
        height: 115px;
    }

    .unsubscribe-box-text {
        margin-top: 8px;
        margin-left: 20px;
        font-size: 12px;
        padding-right: 10px;
    }

    .linkable {
        font-size: 11px;
        line-height: 12px;
    }

    .section.information-container p {
        font-size: 10px;
    }
}


@media (max-width:288px) {
    .container {
        width: 93%;
    }

    .title {
        font-size: 20px;
    }

    .section-header {
        font-size: 0.6em;
    }

    .unsubscribe-box {
        height: 125px;
    }

    .unsubscribe-box-text {
        margin-top: 8px;
        margin-left: 20px;
        font-size: 10px;
        padding-right: 10px;
    }

    .linkable {
        font-size: 10px;
        line-height: 15px;
    }

    .section.information-container p {
        font-size: 10px;
    }
}

.fieldset-container {
    padding: 0px;
}

@media (max-width:600px) {
    .title {
        font-size: 32px;
    }

    .subtitle {
        font-size: 18px;
    }
}

@media (min-width:600px) {
    .title {
        font-size: 36px;
    }
}

