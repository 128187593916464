//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

//-------------------------
// 🌍 Global
//-------------------------

/// If true, includes font face mixins in `_css--font-face.scss` depending on the `css--plex` feature flag
/// @access public
/// @type Bool
/// @group feature-flags
$css--font-face: true !default;

/// If true, includes the `css-helpers()` mixin
/// @access public
/// @type Bool
/// @group feature-flags
$css--helpers: true !default;

/// If true, includes the `css-body()` mixin
/// @access public
/// @type Bool
/// @group feature-flags
$css--body: true !default;

/// If true, include reset CSS
/// @access public
/// @type Bool
/// @group feature-flags
$css--reset: true !default;

/// If true, include default type
/// @access public
/// @type Bool
/// @group feature-flags
$css--default-type: true !default;

/// Used with `css--font-face` feature flag, if true, uses Plex font families instead of Helvetica
/// @access public
/// @type Bool
/// @group feature-flags
$css--plex: true !default;

/// This feature flag was used during development of the v10 experimental grid.
/// TODO: remove in next major release. Synced in `feature-flags` as an adapter in the interim
/// @deprecated (For v10) v10 always uses `@carbon/grid`
/// @access public
/// @type Bool
/// @group feature-flags
$css--use-experimental-grid: false !default;

/// This feature flag was used during development of the v10 experimental grid.
/// TODO: remove in next major release. Synced in `feature-flags` as an adapter in the interim.
/// @access public
/// @type Bool
/// @group feature-flags
/// @deprecated (For v10) v10 always uses `@carbon/grid`
$css--use-experimental-grid-fallback: false !default;

/// If `enable-v11-release` is set to `true`, it automatically enables the custom properties flag.
/// If so, we need a way to disable custom properties, but use the new tokens.
/// @access public
/// @type Bool
/// @group feature-flags
$css--disable-css-custom-properties: false !default;

@import 'carbon-components/scss/globals/scss/feature-flags';
@import 'carbon-components/scss/globals/scss/vars';
@import 'carbon-components/scss/globals/scss/colors';
@import 'carbon-components/scss/globals/scss/theme';
@import 'carbon-components/scss/globals/scss/mixins';
@import 'carbon-components/scss/globals/scss/layout';
@import 'carbon-components/scss/globals/scss/layer';
@import 'carbon-components/scss/globals/scss/spacing';
@import 'carbon-components/scss/globals/scss/typography';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/elements/scss/import-once/import-once';
@import 'carbon-components/scss/globals/scss/css--reset';
@import 'carbon-components/scss/globals/scss/css--font-face';
@import 'carbon-components/scss/globals/scss/css--helpers';
@import 'carbon-components/scss/globals/scss/css--body';
@import 'carbon-components/scss/globals/grid/grid';

//-------------------------
// ⚠️ Manage deprecations
//-------------------------

/// We flag this variable as true if someone uses the globals/scss/styles.scss
/// entry-point. This allows us to collect all the messages and display them at
/// the end of the file instead of bringing it up per-component.
///
/// If a consumer instead gets the components by importing the partial directly,
/// this variable _will not_ be set to true, so the deprecation message will be
/// displayed after the @import.
/// @access private
/// @type Bool
/// @group global-deprecate
$deprecations--entry: true;

/// Collect all deprecation reasons into this list throughout the import cycle.
/// @access private
/// @type Map
/// @group global-deprecate
$deprecations--reasons: ();

/// This message will be prepended to any deprecation notice.
/// @access private
/// @type String
/// @group global-deprecate
$deprecations--message: 'Deprecated code was found, this code will be removed before the next release of Carbon.';

//-------------------------
// 🍕 Components
//-------------------------

//@import 'carbon-components/scss/components/accordion/accordion';
//@import 'carbon-components/scss/components/breadcrumb/breadcrumb';
@import 'carbon-components/scss/components/button/button';
@import 'carbon-components/scss/components/checkbox/checkbox';
//@import 'carbon-components/scss/components/code-snippet/code-snippet';
//@import 'carbon-components/scss/components/combo-box/combo-box';
//@import 'carbon-components/scss/components/content-switcher/content-switcher';
//@import 'carbon-components/scss/components/copy-button/copy-button';
//@import 'carbon-components/scss/components/data-table/data-table';
//@import 'carbon-components/scss/components/date-picker/date-picker';
//@import 'carbon-components/scss/components/dropdown/dropdown';
//@import 'carbon-components/scss/components/file-uploader/file-uploader';
@import 'carbon-components/scss/components/form/form';
//@import 'carbon-components/scss/components/inline-loading/inline-loading';
@import 'carbon-components/scss/components/link/link';
//@import 'carbon-components/scss/components/list-box/list-box';
//@import 'carbon-components/scss/components/list/list';
@import 'carbon-components/scss/components/loading/loading';
//@import 'carbon-components/scss/components/menu/menu';
//@import 'carbon-components/scss/components/modal/modal';
//@import 'carbon-components/scss/components/multi-select/multi-select';
//@import 'carbon-components/scss/components/notification/inline-notification';
//@import 'carbon-components/scss/components/notification/toast-notification';
//@import 'carbon-components/scss/components/number-input/number-input';
//@import 'carbon-components/scss/components/overflow-menu/overflow-menu';
//@import 'carbon-components/scss/components/pagination-nav/pagination-nav';
//@import 'carbon-components/scss/components/pagination/pagination';
//@import 'carbon-components/scss/components/progress-bar/progress-bar';
//@import 'carbon-components/scss/components/progress-indicator/progress-indicator';
//@import 'carbon-components/scss/components/radio-button/radio-button';
//@import 'carbon-components/scss/components/search/search';
//@import 'carbon-components/scss/components/select/select';
//@import 'carbon-components/scss/components/skeleton/skeleton';
//@import 'carbon-components/scss/components/slider/slider';
//@import 'carbon-components/scss/components/structured-list/structured-list';
//@import 'carbon-components/scss/components/tabs/tabs';
//@import 'carbon-components/scss/components/tag/tag';
//@import 'carbon-components/scss/components/text-area/text-area';
@import 'carbon-components/scss/components/text-input/text-input';
//@import 'carbon-components/scss/components/tile/tile';
//@import 'carbon-components/scss/components/time-picker/time-picker';
//@import 'carbon-components/scss/components/toggle/toggle';
//@import 'carbon-components/scss/components/toolbar/toolbar';
@import 'carbon-components/scss/components/tooltip/tooltip';

.bx--checkbox:checked + .bx--checkbox-label::before {
  background-color: #128DDD !important;
  border-color: #128DDD !important;
  box-shadow: #128DDD;
}

@media (min-width: 76px) and (max-width:576px){
.container, .container-sm {
    max-width: 540px;
}
}

//-------------------------------------
// 🔬 Experimental
//-------------------------------------
//@import 'carbon-components/scss/components/pagination/unstable_pagination';
//@import 'carbon-components/scss/components/ui-shell/ui-shell';
//@import 'carbon-components/scss/components/popover/popover';
//@import 'carbon-components/scss/components/treeview/treeview';

//-------------------------------------
// 🙈 Hidden (Not exposed on website)
//-------------------------------------

// Cycle through all the deprecation reasons, if any exist, that have been
// accumulated through the @import process.
@if (length($deprecations--reasons) > 0) {
  $deprecations--message: '';
  @each $reason in $deprecations--reasons {
    $deprecations--message: '#{$deprecations--message} REASON: #{$reason}';
  }

  @warn $deprecations--message;
}
