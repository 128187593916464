@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.category {
    background-color: white;
    border-radius: 5px;
    height: 103px;
    border-color: #DBDEE0;
    border-style: solid;
    border-width: 1.1px;
    margin-bottom: 16px;
    padding-right: 5px;
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.category > div {
    width: 100%;
}

.checkbox-title-container {
    display: flex;
    align-items: center;
}

.title-icon-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.checkbox-container {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}


.bx--checkbox-label::before {
    height: 22px !important;
    width: 22px !important;
    border: 2px solid #80909B !important;
    border-radius: 3px !important;
    margin: 0 !important;
    background-color: #FFFFFF !important;
}

.bx--checkbox:checked + .bx--checkbox-label::before {
    height: 22px;
    width: 22px;
    border-radius: 3px;
    background-color: #0E75C4;
}

.bx--checkbox:disabled + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-disabled=true]::before {
    border-color: #c6c6c6 !important;
}

.bx--checkbox-label::after {
    top: 0.5rem;
    left: 0.5rem;
}

.icon {
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    display: inline;
    display: flex;
    background: center center no-repeat;
    margin-right: 10px;
}

.assignment_late_icon {
    background-image: url(../../icons/assignment_late_icon.svg);
}

.EmailIcon {
    background-image: url(../../icons/EmailIcon.svg);
}

.flameicon {
    background-image: url(../../icons/flameicon.svg);
}

.monitization_on_icon {
    background-image: url(../../icons/monitization_on_icon.svg);
}

.payment_icon {
    background-image: url(../../icons/payment_icon.svg);
}

.shadow {
    box-shadow: 0px 0px 14px 11px rgba(0,0,0,0.31);
    -webkit-box-shadow: 0px 0px 14px 11px rgba(0,0,0,0.31);
    -moz-box-shadow: 0px 0px 14px 11px rgba(0,0,0,0.31);
}

.description2 {
    font-size: 11px;
}

.category-title {
    color: #213848;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
}

.description1 {
    color: #2F4656;
}

@media (min-width:420px) and (max-width:768px) {
    .category-title {
        width: 100%;
        color: #213848;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 27px;
    }

    .description1 {
        margin-top: 0em;
        font-size: 16px;
    }

    .description2 {
        font-size: 0.7em;
    }

    .checkbox-container {
        padding: 20px;
    }
}

@media (min-width:348px) and (max-width:420px) {
    
    .category-title {
        width: 100%;
        color: #213848;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 27px;
    }

    .bx--grid {
        margin-top: 5px;
    }

    .description1 {
        margin-top: 1em;
        font-size: 16px;
    }

    .description2 {
        font-size: 0.7em;
    }

    .checkbox-container {
        padding: 20px;
    }
}

@media (max-width:348px) {
    
    .category-title {
        color: #213848;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 12px;
    }

    .bx--grid {
        margin-top: 5px;
    }

    .description1 {
        margin-top: 1em;
        font-size: 0.6em;
    }

    .description2 {
        font-size: 0.6em;
    }
    .checkbox-container {
        padding: 20px;
    }
}

@media (max-width:600px) {

    .category {
        height: auto;
    }

    .cat-text-container {
        padding: 24px 40px 24px 0px;
    }
}
