.loadingContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fefefe;
    opacity: 0.8;
}

.center-spinner {
    -webkit-animation-duration: .69s;
    animation-duration: .69s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: rotate;
    animation-name: rotate;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    width: 5.5rem;
    height: 5.5rem;
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    min-height: 55px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    min-height: 55px;
    width: 100%;
    align-items: center;
    justify-content: center;
}
